



































































































import useEvent from '@/use/event'
import useSelectItems from '@/use/selectItems'
import { defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

  export default defineComponent ({

    setup(_, { root }) {
      const { getEventTypeColor, getEventTypeName, getEventStatus, getEventStatusColor, getEventStatusName } = useEvent({ root })
      const { eventTypeItems } = useSelectItems({ root })

      const calendar = ref<any>(null)

      const state = reactive({
        loading: false,
        value: '',
        events: [] as any[],
        empty: false,
        items: [],
        total: 0,
        loaded: false,
        selectedEvent: {} as Event,
        selectedElement: null as null | EventTarget,
        selectedOpen: false,
        type: 'month',
        typeToLabel: {
          month: root.$t('layout.calendar.labelType.month'),
          week: root.$t('layout.calendar.labelType.week'),
          day: root.$t('layout.calendar.labelType.day')
        },
        eventType: ''
      })

      const getEventColor = (event: any) => event.color

      // Start fetch events

      const fetchData = () => {
        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        state.loading = true

        axiosInstance
          .get('event',  { params: { type: state.eventType || undefined } })
          .then(({ data: { events, total } }) => {
            state.empty = false
            state.items = events.map((event: any, index: number) => ({ id: index, name: '',...event }))
            state.total = total
          })
          .catch((error) => {
            if (error.response.status === 404) {
              state.empty = true
              state.items = []
              state.total = 0
            } else {
              console.log(error)
            }
          })
          .finally(() => {
            state.loaded = true
            state.loading = false
          })
      }

      // End fetch events

      onMounted(fetchData)
      watch(() => state.eventType, fetchData)

      const getEvents = () => {
        const events: any = []

        state.items.map((item: any) => {
          const start = new Date(item.startDate).toISOString().slice(0,10)
          const end = new Date(item.endDate).toISOString().slice(0,10)
          let color = ''

          if (item.type == 'conference') {
            color = 'deep-purple'
          } else if (item.type == 'online') {
            color = 'teal'
          } else if (item.type == 'course') {
            color = 'pink'
          } else {
            color = 'blue-grey'
          }

          events.push({
            name: item.name,
            start: start,
            end: end,
            color: color,
            type: item.type,
            data: item
          })
        })

        state.events = events
      }

      watch(() => state.items, getEvents)

      const next = () => (calendar.value as any).next()

      const prev = () => (calendar.value as any).prev()

      const setToday = () => state.value = ''

      const showEvent = ({ nativeEvent, event }: { nativeEvent: Event; event: Event }) => {
        const open = () => {
          state.selectedEvent = event
          state.selectedElement = nativeEvent.target
          setTimeout(() => {
            state.selectedOpen = true
          }, 10)
        }

        if (state.selectedOpen) {
          state.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
      }

      const viewDay = ({ date }: any) => {
        state.value = date
        state.type = 'day'
      }

      return {
        calendar,
        state,
        getEvents,
        getEventColor,
        next,
        prev,
        setToday,
        showEvent,
        viewDay,
        getEventTypeColor,
        getEventTypeName,
        getEventStatus,
        getEventStatusColor,
        getEventStatusName,
        eventTypeItems
      }
    }
  })
